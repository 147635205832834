<template>
  <div class="main__checkout white">
    <div class="main__item__header">
      <span class="main__item__header__title text-h6 dark-cyanBlue font-weight-regular">
        Checkout
      </span>
    </div>
    <div v-if="selectedNumbers.length" class="main__item__numbers py-2">
      <div v-for="item in selectedNumbers" :key="item.id" class="d-flex justify-space-between align-center my-4">
        <span class="text-subtitle-2 font-weight-semi-bold medium-cyanBlue">
          {{ item.tn }}
        </span>
        <img src="@/assets/figma/fi_remove.svg" alt="remove" class="pointer" @click="$emit('removeNumber', item.tn)">
      </div>
    </div>
    <div v-else class="d-flex justify-center">
      <span class="text-subtitle-1 font-weight-semi-bold dark-cyanBlue">No selected numbers</span>
    </div>
    <v-divider></v-divider>
    <!-- <div v-if="!isFree" class="main__item__type pt-3 pb-2">
      <span class="text-subtitle-1 font-weight-semi-bold dark-cyanBlue"> Payment period </span>
      <v-radio-group v-model="paymentPeriod" @change="$emit('changeDurationType', paymentPeriod)">
        <div class="d-flex justify-space-between align-center mb-2">
          <v-radio label="Month" value="month" />
          <span>
            ${{ defaultAmount.monthly }}
          </span>
        </div>
        <div class="d-flex justify-space-between align-center">
          <v-radio label="Year" value="year" />
          <span>
            ${{ defaultAmount.annually }}
          </span>
        </div>
      </v-radio-group>
    </div> -->
    <div v-if="!isFree" class="main__item__result pb-6 pt-4">
      <div class="main__item__result__total d-flex justify-space-between align-center">
        <span class="main__item__header__title text-h6 dark-cyanBlue font-weight-regular"> Total: </span>
        <span>${{ total }}</span>
      </div>
    </div>
    <div class="main__item__payment">
      <!-- <div class="main__item__payment__paypal d-flex justify-center bg-golden rounded-sm py-3 mb-2"
        @click="$emit('onPaypal')">
        <v-btn class="btn">
          <img src="@/assets/figma/fi_paypal.svg" alt="paypal">
        </v-btn>
      </div> -->
      <div
        v-if="isFree"
        class="main__item__payment__paypal"
        >
        <v-btn
          :loading="stripeLoading"
          :disabled="disabled"
          class="btn free-btn white--text text-subtitle-1 bg-darkCyan rounded-sm py-4 w-100 d-flex justify-space-evenly"
          @click="$emit('onFree')"
        >
          <span class="text-wrapper">
            <span class="white--text text-capitalize">Free</span>
            <span class="question">?</span>
            <span class="answer">You can use FREE number for inbound call but not outbound call.</span>
          </span>
          <span class="white--text text-capitalize">0.00</span>
        </v-btn>
      </div>
      <div
        v-else
        class="main__item__payment__paypal d-flex justify-center white--text text-subtitle-1 bg-darkCyan rounded-sm py-3"
        @click="$emit('onStripe')">
        <v-btn class="btn" :loading="stripeLoading" :disabled="disabled">
          <img src="@/assets/figma/fi_stripe.svg" alt="stripe">
          <!-- <span class="white--text text-capitalize">{{ text }}</span> -->
        </v-btn>
      </div>
    </div>
    <div class="main__item__information bg-ghostWhite pa-4 mt-6">
      <p class="text-caption font-weight-regular dark-cyanBlue mb-0">
        Before the expiration date, you need to renew the numbers you purchased. Otherwise they will be released at the
        expiration date.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Checkout',
  data: () => {
    return {
      paymentPeriod: 'month',
      amount: {
        toll_free: {
          monthly: '20',
          annually: '200'
        },
        long_code: {
          monthly: '12',
          annually: '120'
        }
      }
    }
  },
  props: {
    selectedNumbers: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    },
    stripeLoading: {
      type: Boolean,
      default: false
    },
    text: {
      type: String
    },
    numberType: {
      type: String,
      default: ''
    },
    isFree: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    defaultAmount () {
      return this.numberType === 'long_code'
        ? this.amount.long_code
        : this.amount.toll_free
    }
  }
}
</script>

<style lang="scss" scoped>
.main__item__numbers,
.main__item__type {
  border-bottom: 1px solid #CED4DA;
}

.main__item__information {
  border-left: 2px solid darkblue;
  margin-inline: -16px;
}

.main__item__header__title,
.main__item__type>span,
.main__item__result>span,
.main__item__information>p,
.main__item__numbers span {
  font-family: 'SF Pro Display' !important;
}

.main__item__payment>div {
  .btn {
    height: unset;
    min-width: unset;
    padding: 0;
    background: transparent;
    box-shadow: none;
  }
  .free-btn {
    font-size: 20px;
    position: relative;
    background: #353738 !important;
    justify-content: space-evenly;
    padding-block: 10px;
    .text-wrapper {
      position: relative;
    }
    .question {
      color: #fff;
      font-size: 14px;
      line-height: 1;
      border: 1px solid #fff;
      border-radius: 50%;
      padding: 2px 4px 1px 2px;
      width: 20px;
      aspect-ratio: 1;
      display: inline-block;
      position: absolute;
      top: -10px;
      right: -23px;
      &:hover {
        background: #555;
        + .answer {
          display: block;
        }
      }
    }
    .answer {
      position: absolute;
      left: 116%;
      top: -30px;
      display: none;
      z-index: 10;
      background: #fff;
      padding: 10px;
      border: 1px solid #000;
      color: #000 !important;
      border-radius: 7px;
      font-size: 14px;
      text-transform: none;
      &:hover {
        display: block;
      }
    }
  }
}

.main__item__numbers {
  overflow-y: auto;
  max-height: 270px;
  padding-right: 16px;
}
</style>
